.content-card-background{
  background-color: rgb(255, 255, 255);
  margin-top: 5rem;
}

.homepage-content-card-background {
  background: linear-gradient(to bottom, #ffffff, #ebebeb);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
  border-radius: 0.5rem;
}

.video-iframe {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.image-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width : 768px) {
  .navbar-collapse {
    background-color: rgba(237, 181, 97, 1);
  }
}

.map{
  height: 400px;
  width: 450px;
}

@media only screen and (max-width: 768px) {
  .map {
    width: 300px;
    height: 300px;
  }
}

.navbar-light .navbar-nav .nav-link {
  font-size: 1.7rem;
  font-weight: 510;
  color:rgb(255, 255, 255);
  text-shadow: 
  -1px -1px 0 rgba(0, 0, 0, 0.5),  /* Top-left shadow */
   1px -1px 0 rgba(0, 0, 0, 0.5),  /* Top-right shadow */
  -1px  1px 0 rgba(0, 0, 0, 0.5),  /* Bottom-left shadow */
   1px  1px 0 rgba(0, 0, 0, 0.5),  /* Bottom-right shadow */
   .15rem .15rem .15rem rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover {
  color: rgb(230, 230, 230);
}

.navbar-light .navbar-nav .nav-link:focus {
  color: rgb(230, 230, 230);
}

.fixed-top{
  transition: background-color 200ms linear;
}

.fixed-top.scrolled {
  background-color: rgba(237, 181, 97, 0.7);
  color: white;
  transition: background-color 400ms linear;
}

.text-justify{
  text-align: justify;
}

.carousel-item {
  height: 66vh;
  min-height: 350px;
  background: no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: auto;
}

.carousel-container {
  width: 100%;
  box-shadow: 0 0.4em 0.625em rgba(0, 0, 0, 0.5);
  margin-bottom: 4rem;
}

.footer {
  background-color: rgba(237, 181, 97, 0.9);
}

.footer-parish-title {
  font-size: large;
  margin-bottom: .3rem;
}

.footer-parish-copyright {
  font-size: small;
  margin-bottom: .1rem;
}

.line-breaker {
  border: 1px solid rgb(128, 128, 128);
}

.homepage-line-breaker {
  border: 1px solid rgb(0, 0, 0, 0.3);
}

body{
  background: url('https://firebasestorage.googleapis.com/v0/b/hosteradiceparish.appspot.com/o/content%2Fimages%2Fbackground%2FPozadiFHD1.jpg?alt=media&token=ff866aa9-5ff6-4d9e-a8f0-5b5c742f128e') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.link{
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.strong{
  font-weight: 600;
  color:rgb(75, 75, 75);
}

.link:hover{
  text-decoration: none;
  text-shadow: '1px 1px 1px #555';
}

h3{
  color:rgb(100, 100, 100)
}

h2 {
  margin-top: 1rem;
}

.text-right{
  text-align: right;
}

.trash-button {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-left: 1rem;
  background-image: url('/public/assets/icons/trash.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  cursor: pointer;
}

.trash-button:hover {
  background-color: #d9d9d9;
}